import { GET, GET_ALL, POST } from './Clients';

const FRONTEND_APIS_PREFIX = '/marketplace/frontend-apis/v1';
const PROMOTION_API_PREFIX = '/marketplace/promotion/v1';

export async function getPromosActive({ ctx }) {
  return GET_ALL({
    ctx,
    url: `${PROMOTION_API_PREFIX}/promotion/active`,
  });
}

export async function getPromotionListByIDs({ ctx, promotionIDs }) {
  return GET({
    ctx,
    url: `${PROMOTION_API_PREFIX}/promotion`,
    params: {
      q: JSON.stringify({ listIds: promotionIDs }),
    },
  });
}

export async function getVoucherActiveOrder({ ctx }) {
  return GET_ALL({
    ctx,
    url: `/marketplace/order/v2/voucher/active`,
    params: {
      getValidate: true,
    },
  });
}

export async function getVoucherActive({ ctx }) {
  return GET_ALL({
    ctx,
    url: `${PROMOTION_API_PREFIX}/voucher/active`,
  });
}

export async function getPromoDetailByVoucherCode({ voucherCode }) {
  return GET({
    url: `${PROMOTION_API_PREFIX}/voucher`,
    params: { voucherCode },
  });
}

export async function getMyVoucher({ ctx, offset, limit }) {
  return GET({
    ctx,
    url: `${PROMOTION_API_PREFIX}/me/voucher`,
    params: {
      offset,
      limit,
      getTotal: true,
    },
  });
}

export async function getActiveCampaign({ ctx, limit = 100 }) {
  return GET({
    ctx,
    url: `${PROMOTION_API_PREFIX}/campaign/active/list`,
    limit,
  });
}

export async function getVoucherActiveConfig({ offset, limit = 20, search = '', signal }) {
  return GET({
    url: '/marketplace/order/v2/voucher/active',
    params: {
      getValidate: true,
      getTotal: true,
      offset,
      limit,
      search,
    },
    signal,
  });
}

export async function getMyHistoryVoucher({ ctx, offset, limit, q = null }) {
  return GET({
    ctx,
    url: `${PROMOTION_API_PREFIX}/me/voucher/history`,
    params: {
      getTotal: true,
      offset,
      limit,
      q,
    },
  });
}

export async function gamificationJoin(gamificationID) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/me/gamification/join`,
    body: { gamificationID },
  });
}

export async function postSpinLuckyWheel(code = '', type = '') {
  return POST({
    url: `${PROMOTION_API_PREFIX}/lucky-wheel/spin`,
    body: {
      code,
      type,
    },
  });
}

export async function getSelfLuckyWheelRewards(luckyWheelCode = '') {
  return GET({
    url: `${PROMOTION_API_PREFIX}/me/lucky-wheel`,
    params: {
      luckyWheelCode,
    },
  });
}

export async function getLogSelfLuckyWheel({ luckyWheelCode, limit = 100, offset = 0, type = '' }) {
  return GET({
    url: `${PROMOTION_API_PREFIX}/me/lucky-wheel-log/list`,
    params: {
      luckyWheelCode,
      limit,
      type,
      offset,
    },
  });
}

export async function getMissionSelfLuckyWheel({ luckyWheelCode }) {
  return GET({
    url: `${PROMOTION_API_PREFIX}/me/lucky-wheel-mission/list`,
    params: {
      luckyWheelCode,
    },
  });
}

export async function postGamificationScore({
  type = '',
  customerID,
  accountID,
  action = '',
  luckyWheelCode,
  gamificationDetailID,
  socialNetwork = '',
}) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/gamification-score`,
    body: {
      type,
      customerID,
      accountID,
      data: {
        luckyWheelCode,
        gamificationDetailID,
        socialNetwork,
      },
      action,
    },
  });
}

export async function postShareLog({ customerID, accountID, source = '', page = '', screen = '', shareUrl = '', platform = '' }) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/share-log`,
    body: {
      customerId: customerID,
      accountId: accountID,
      source,
      page,
      screen,
      shareUrl,
      platform,
    },
  });
}

export async function postVoucherList({
  ctx,
  accountID,
  cart = {},
  scope = '',
  limit = 200,
  search = '',
  getValidate = true,
  getTotal = true,
  getBySellerInCart = false,
  sellerCode = '',
  getVoucherToCollect = false,
  skipVoucherCollected = false,
  sku = '',
  skipVoucherOwner = false,
  storeCode = '',
  getVoucherAuto = true,
  getMedxVoucherOnly = false
}) {
  return POST({
    ctx,
    url: `${PROMOTION_API_PREFIX}/me/voucher/list`,
    body: {
      accountId: accountID,
      cart: {
        paymentMethod: cart.paymentMethod,
        price: cart.price,
        totalItem: cart.totalItem,
        redeemCode: cart.redeemCode,
        cartItems: cart.cartItems,
        redeemApplyResult: cart.redeemApplyResult,
      },
      scope, // me | other | product-detail
      limit, // default 20
      getValidate,
      getTotal,
      search,
      getBySellerInCart,
      sellerCode, // using on product detail
      sku, // prevent some product not allow using voucher
      getVoucherToCollect,
      skipVoucherCollected, // return data without voucher collected
      skipVoucherOwner, // return data without voucher owner
      storeCode, // return data voucher for vendor seller, only one in storeCode and sellerCode can appear in same time
      getVoucherAuto, // cart will auto apply auto-voucher
	  getMedxVoucherOnly, // get only medx voucher
    },
  });
}

export async function getContentPromoGift({ ctx }) {
  return GET({
    ctx,
    url: `${PROMOTION_API_PREFIX}/me/promo-gift`,
  });
}

export async function getSelfChenkin() {
  return GET({ url: `${PROMOTION_API_PREFIX}/me/checkin` });
}

export async function postCheckin({ code = '' }) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/me/checkin`,
    body: {
      code,
    },
  });
}

export async function getSelfLoyaltyList({ ctx } = {}) {
  return GET({
    ctx,
    url: `${FRONTEND_APIS_PREFIX}/me/loyalty/list`,
  });
}

export async function postCollectVoucher({ voucherCode = '', currentPage = '', fullLink = '', sku = '', screen = '' }) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/me/voucher/collect`,
    body: {
      voucherCode,
      collectSource: {
        page: currentPage,
        fullLink,
        screen,
        sku,
      },
    },
  });
}

export async function getPromoLists({ skus, getVoucherInfo = false, signal }) {
  return POST({
    url: `${PROMOTION_API_PREFIX}/voucher/list-by-sku`,
    body: {
      skus,
      getVoucherInfo,
    },
    signal,
  });
}
